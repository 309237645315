<script>
import DetailingForm from './DetailingForm'

export default {
  extends: DetailingForm,

  created() {
    this.getDetailing(this.$route.params.id).then(response => {
      this.form = response
    })
  },

  methods: {
    onSubmit() {
      this.updateDetailing(this.form.id, this.form)
        .then(() => {
          this.successSubmit({ message: 'Детализация успешно изменена', routeName: 'detailing' })
        })
        .finally(() => { this.afterSubmit() })
    }
  }
}
</script>
